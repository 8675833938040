<template>
  <footer class="kids-footer">
    <div class="container-fluid footer-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <a class="footer-brand" href="#">
              <img src="../assets/KU-logowhite.png" alt="kids-unlimited" />
            </a>
            <p>
              Copyright @ VB LIVE Skill School 2021 all right reserved
            </p>
          </div>
          <div class="col-md-2 offset-md-2">
            <ul class="list-unstyled links-wrap">
              <li>Home</li>
              <li>
                <router-link :to="{ name: 'home' }">Home</router-link>
              </li>
              <li>
                <router-link to="/about-us">About</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'membership' }"
                  >Membership</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'courses' }">Courses</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'webinars' }">Events</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'contactUs' }"
                  >Contact Us</router-link
                >
              </li>
              <li>
                <a
                  v-if="this.$store.getters['studentAuth/isLoggedIn'] == true"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Feedback
                </a>
              </li>
              <li>
                <router-link to="/terms-and-conditions"
                  >Terms & Conditions</router-link
                >
              </li>
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <ul class="list-unstyled social-div links-wrap">
              <li>Follow us</li>
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i>facebook</a>
              </li>
              <li>
                <a href="#"><i class="fab fa-instagram"></i>instagram</a>
              </li>
              <li>
                <a href="#"><i class="fab fa-linkedin-in"></i>twitter</a>
              </li>
              <li>
                <a href="#"><i class="fab fa-twitter"></i>Linkedin</a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Feedback</h5>
                <button
                  type="button"
                  class="btn-close"
                  ref="closeModal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form @submit.prevent="savePupaFeedback">
                <div class="modal-body">
                  <div class="input-wrapper">
                    <div class="form-floating col-md-12">
                      <textarea
                        class="form-control"
                        v-model="feedbackMessage"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style="height: 100px"
                      ></textarea>
                      <label for="floatingTextarea2">Feedback</label>
                    </div>
                    <div
                      v-if="errors && errors.feedbackMessage"
                      class="error-msg"
                    >
                      {{ errors.feedbackMessage[0] }}
                    </div>
                    <br />
                    <star-rating v-model:rating="rating"></star-rating>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" class="btn cta-primary">
                    <span>
                      Submit
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import errorLog from "@/errorLog";
import PupaFeedbackService from "@/services/PupaFeedbackService";
import StarRating from "vue-star-rating";
export default {
  name: "Footer",
  components: { StarRating },
  data() {
    return {
      rating: 0,
      feedbackMessage: "",
      isSubmitting: false,
      errors: []
    };
  },
  computed: {
    user() {
      let user = JSON.parse(this.$store.getters["studentAuth/user"]);
      return user;
    }
  },
  methods: {
    savePupaFeedback() {
      if (this.feedbackMessage == "") {
        this.$toast.error("Please enter comment");
      } else {
        const data = {
          rating: this.rating,
          feedback: this.feedbackMessage
        };
        this.isSubmitting = true;
        PupaFeedbackService.savePupaFeedback(data)
          .then(result => {
            this.isSubmitting = false;
            if (result.data.status == "error") {
              this.$toast.error(result.data.message);
            } else if (result.data.status == "validation_error") {
              this.errors = result.data.message || {};
            } else {
              this.feedbackMessage = "";
              this.rating = "";
              this.$toast.success(result.data.message);
              this.$refs.closeModal.click();
            }
          })
          .catch(error => {
            this.isLoading = false;
            errorLog.log(error);
          });
      }
    }
  }
};
</script>

<style lang="scss">
.kids-footer {
  padding-top: 4rem;
  background: #261c15;
  a.footer-brand {
    display: block;
    width: 90px;
    padding-bottom: 20px;
  }
  p {
    color: rgba(255, 255, 255, 0.623);
    font-size: 13px;
    font-weight: 400;
    font-size: 15px;
    margin-top: 10%;
  }
  .links-wrap {
    margin-top: 2rem;
    li {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      &:nth-child(1) {
        margin-bottom: 15px;
      }
      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 15px;
        color: #fff;
        line-height: 18px;
        &:hover {
          color: #f67f29;
        }
        i {
          font-size: 10px;
          margin-right: 10px;
          color: #a6a6a6;
        }
      }
    }
  }
}
</style>
