<template>
  <footer class="nest-footer">
    <div class="container-fluid footer-wrapper">
      <div class="container mb-3">
        <div class="row justify-content-between align-items-end">
          <div class="col-md-8">
            <a class="footer-brand" href="#"
              ><img src="/img/KU-logowhite.87f27a3d.png" alt="kids-unlimited"
            /></a>
            <ul class="list-unstyled links-wrap">
              <li><a href="/privacy-policy" class="">Privacy Policy</a></li>
              <li>
                <a href="/terms-and-conditions" class=""
                  >Terms &amp; Conditions</a
                >
              </li>
              <li>
                <a
                  v-if="this.$store.getters['studentAuth/isLoggedIn'] == true"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Feedback
                </a>
              </li>
            </ul>
            <ul class="list-unstyled links-wrap">
              <li><a href="/" class="">Home</a></li>
              <li><a href="/about-us" class="">About</a></li>
              <li><a href="/membership" class="">Membership</a></li>
              <li><a href="/courses" class="">Courses</a></li>
              <li><a href="/contact-us" class="">Contact Us</a></li>
              <li>
                <a href="/terms-and-conditions" class=""
                  >Terms &amp; Conditions</a
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul class="list-unstyled social-div links-wrap">
              <li>Find us at</li>
              <ul class="list-unstyled d-flex">
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="subfooter">
      <div class="container">
        <p>Copyright @ VB LIVE Skill School 2021 all right reserved</p>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Feedback</h5>
            <button
              type="button"
              class="btn-close"
              ref="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveFeedback">
            <div class="modal-body">
              <div class="input-wrapper">
                <div class="form-floating col-md-12">
                  <label for="floatingTextarea2">Feedback</label>
                  <textarea
                    class="form-control"
                    v-model="feedbackMessage"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style="height: 100px"
                  ></textarea>
                </div>
                <div v-if="errors && errors.feedbackMessage" class="error-msg">
                  {{ errors.feedbackMessage[0] }}
                </div>
                <br />
                <star-rating v-model:rating="rating"></star-rating>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn cta-primary">
                <span>
                  Submit
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import errorLog from "@/errorLog";
import NestEventService from "@/services/NestEventService";
import StarRating from "vue-star-rating";
export default {
  name: "NestFooter",
  components: { StarRating },
  data() {
    return {
      rating: 0,
      feedbackMessage: "",
      isSubmitting: false,
      errors: []
    };
  },
  computed: {
    user() {
      let user = JSON.parse(this.$store.getters["studentAuth/user"]);
      return user;
    }
  },
  methods: {
    saveFeedback() {
      if (this.feedbackMessage == "") {
        this.$toast.error("Please enter comment");
      } else {
        const data = {
          rating: this.rating,
          feedback: this.feedbackMessage
        };
        this.isSubmitting = true;
        NestEventService.saveFeedback(data)
          .then(result => {
            this.isSubmitting = false;
            if (result.data.status == "error") {
              this.$toast.error(result.data.message);
            } else if (result.data.status == "validation_error") {
              this.errors = result.data.message || {};
            } else {
              this.feedbackMessage = "";
              this.rating = "";
              this.$toast.success(result.data.message);
              this.$refs.closeModal.click();
            }
          })
          .catch(error => {
            this.isLoading = false;
            errorLog.log(error);
          });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/style/nestfooter.scss";
h3.active {
  color: #f67f29;
}
</style>
