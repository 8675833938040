import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/style.scss";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import VCalendar from "v-calendar";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Vue3VideoPlayer, {
  lang: "en"
});
app.use(VCalendar, {});
app.use(VueSweetalert2);
app.use(Toaster, {
  position: "top-right",
  duration: 2000
});
app.mount("#app");
