<template>
  <div id="nav" v-if="isComingSoon == false">
    <div class="nav-wrapper" :class="navbarClass">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <router-link
            aria-current="page"
            :to="{ name: 'home' }"
            class="navbar-brand"
          >
            <img src="../assets/KU-logo.png" alt="kids-unlimited" />
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav ms-auto mb-2 me-3 mb-lg-0">
              <li class="nav-item">
                <router-link :to="{ name: 'Nest' }" class="nav-link active">
                  <img class="nest-logo" src="../assets/nest-logo.png" alt="" />
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'pupa' }" class="nav-link active">
                  <img class="pupa-logo" src="../assets/pupa-logo.png" alt="" />
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="this.$store.getters['studentAuth/isLoggedIn'] == true"
              >
                <router-link class="nav-link" :to="{ name: 'profile' }">{{
                  user.first_name
                }}</router-link>
              </li>
              <li>
                <router-link
                  v-if="this.$store.getters['studentAuth/isLoggedIn'] != true"
                  class="btn cta-outline"
                  :to="{ name: 'login', query: { p: currentUrl() } }"
                >
                  Login
                </router-link>
                <a
                  class="btn cta-outline"
                  @click="logout"
                  v-if="this.$store.getters['studentAuth/isLoggedIn'] == true"
                  >Logout</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
export default {
  name: "Navbar",
  data: function() {
    return {
      scrollPosition: null,
      navbarClass: ""
    };
  },
  // created(){
  // //    this.logout()
  //    this.$store.dispatch("studentAuth/authRequest", false);
  // },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.navbarClass = this.scrollPosition == 0 ? "" : "fixed";
    },
    currentUrl() {
      var currentPath = this.$route.path;
      var stringArray = currentPath.split("/");
      if (stringArray[1] == "nest") {
        return "Nest";
      } else {
        return "Pupa";
      }
    },
    logout: function() {
      AuthService.logout()
        .then(result => {
          this.$store.dispatch("studentAuth/logout");
          console.log(result);
          this.$router.push({
            name: "Home"
          });
        })
        .catch(error => {
          this.error = error;
          this.$store.dispatch("studentAuth/logout");
          this.$router.push({
            name: "home"
          });
        });
    }
  },
  computed: {
    user() {
      let user = this.$store.state.studentAuth.user;

      if (user.length == undefined) {
        this.logout();
        user = {};
      } else {
        user = JSON.parse(user);
      }
      return user;
    },
    isComingSoon() {
      let isComingSoon = JSON.parse(process.env.VUE_APP_COMING_SOON);
      return isComingSoon;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  }
};
</script>

<style lang="scss">
@import "@/style/navbar.scss";
</style>
