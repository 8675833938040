<template>
  <Navbar v-if="currentRouteName != 'meeting'" />
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer v-if="currentRouteName != 'meeting' && currentUrl != 'nest'" />
  <NestFooter
    v-if="currentRouteName != 'meeting' && currentUrl == 'nest'"
  ></NestFooter>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import NestFooter from "@/components/NestFooter.vue";

export default {
  name: "app",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentUrl() {
      var currentPath = this.$route.path;
      var stringArray = currentPath.split("/");
      if (stringArray[1] == "dashboard") {
        return stringArray[2];
      } else {
        return stringArray[1];
      }
    }
  },
  components: {
    Navbar,
    Footer,
    NestFooter
  }
};
</script>
<style lang="css">
/* For Fade transistion style when router changes [starts] */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* For Fade transistion style when router changes [ends] */
</style>
