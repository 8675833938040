import Http from "@/services/Http";
export default {
  getSchedules() {
    return Http().post("nest/events");
  },
  getFeedback(data) {
    return Http().post("nest/feedbacks", data);
  },
  saveFeedback(data) {
    return Http().post("nest/feedback/create", data);
  },
  getRecordings(data) {
    return Http().post("nest/recordings", data);
  },
  getNextEvent() {
    return Http().post("nest/events/next");
  },
  getAboutNest() {
    return Http().post("nest/about");
  },
  getLifeSkills() {
    return Http().post("nest/life-skills");
  },
  getTestimonials() {
    return Http().post("nest/testimonials");
  },
  getFaq() {
    return Http().post("nest/faq");
  },
  getMembership() {
    return Http().post("nest/membership/details");
  },
  getContents(data) {
    return Http().post("/nest/content", data);
  }
};
